<template>
  <section id="panoramaW" class="panorama-info p-0" :class="isFullScreen">

    <div class="controls">
      <div class="fs-button" @click="toggleFullScreen()">
        <d-icon pack="fas" :icon="fullScreenIcon" size="2xs" />
      </div>
      <div class="pie">
        <div :style="pieSector"></div>
      </div>
   </div>

    <div v-if="info.content !== 'video'" id="panoramaWrapper"></div>

    <div v-else id="panoramaWrapper" class="view360-container">
      <canvas class="view360-canvas"></canvas>
      <video id="panoVideo"
        :src="info.video.url"
        playsInline
        crossOrigin="anonymous"
        loop></video>
    </div>


    <div v-if="!sensor" class="poster">
      <div class="gyro-button" @click="enableSensor()">
        <d-icon
          pack="far"
          icon="360-degrees"
          size="is-medium"/>
       </div>
    </div>
  
  </section>

</template>

<script>
import './PanoramaInfo.scss'

import { PanoViewer } from "@egjs/view360"

export default {
  components: {  },
  props: {
    info: {
      type: Object,
      required: true,
    },
    isOnComputer: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      player: null,
      panoViewer: undefined,
      sensor: false,
      vr: false,
      mode: 'none',
      projectionType: 'equirectangular',
      fullscreen: false,
      view: {
        yaw: 0
      }
    }
  },
  computed: {
      panWrapper() {
        return document.getElementById("panoramaWrapper")
      },
      panW() {
        return document.getElementById("panoramaW")
      },
      isFullScreen(){
        return this.fullscreen ? 'fullscreen' : null
      },
      initSize(){
        return {
          width: this.panWrapper.clientWidth,
          height: this.panWrapper.clientHeight
        }
      },
      pieSector(){
        const rot = 180 - this.view.yaw
        const ang = 0
        return `transform: rotate(${rot}deg) skew(${ang}deg)`

      },
      fullScreenIcon() {
        return this.isFullScreen ? 'compress' : 'expand'
      }
  },
  methods: {
    setGyroMode(mode) {
      this.mode = mode;
      console.log('setting ' +mode,this.panoViewer.setGyroMode(mode)) 
    },
    enableSensor(){
        this.setGyroMode('yawPitch')
        this.panoViewer.enableSensor()
        this.sensor = true

        if(this.info.content == 'video') document.getElementById("panoVideo").play()
    },
    toggleFullScreen(){
      this.fullscreen = !this.fullscreen

      this.panoViewer.updateViewportDimensions({
        width: this.fullscreen ? window.innerWidth : this.initSize.width,
        height: this.fullscreen ? window.innerHeight : this.initSize.height
      })
    }
  },
  mounted() {

    if(this.info.content !== 'video') {

      this.panoViewer = new PanoViewer(
        this.panWrapper,
        {
          image: this.info.image.url,
          projectionType: this.projectionType,
          gyroMode: this.mode,
          width: this.initSize.width,
          height: this.initSize.height,
        }
      )

    } else {

      this.panoViewer = new PanoViewer(
        this.panWrapper,
        {
          video: document.getElementById("panoVideo"),
          projectionType: this.projectionType,
          gyroMode: this.mode,
          autoplay: false,
          width: this.initSize.width,
          height: this.initSize.height,
        }
      )

    }

    this.panoViewer.on("viewChange",(e)=>{

      this.view = {
        yaw: e.yaw
      }
    })

  },
};
</script>